<template>
  <div>
    <div class="router-header flex flex-wrap items-center mb-6">
      <div class="content-area__heading">
        <h2 class="mb-1">Center Dashboard</h2>
      </div>
    </div>
    <div class="mb-4 flex items-center justify-between mx-10">
      <div class="vx-breadcrumb asdf md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <a
              href="#"
              class="nav-link d-flex align-items-center active"
              @click.stop="$router.push({ name: 'director-dashboard' })"
            >
              <feather-icon
                icon="HomeIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
              />
            </a>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7" />
                  <polyline points="6 17 11 12 6 7" />
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>{{ title | capitalize }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!--   room list-->
    <vx-card class="mb-base">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Room</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2">
          <vs-button
            v-if="selectedRooms.length !== totalRoomIds.length"
            class="ml-auto"
            @click="selectAllRooms"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllRooms">
            Unselect All
          </vs-button>
          <vs-button
            class="ml-2"
            color="primary"
            type="filled"
            @click="addRoomHandler"
            >Add room</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- Avatar -->
      <horizontal-scroll>
      <ul class="room-list mb-4" v-if="roomList.length">
        <li
          v-for="(room, index) in roomList"
          v-bind:key="index"
          :class="selectedRooms.includes(room._id) ? 'active' : ''"
        >
          <vs-avatar size="large" :src="room.logo" @click="selectRoom(room)" />
          <h3
            class="h5"
            style="color: #289AD5; font-size: 14px; line-height: 18px;"
          >
            {{ room.name }}
          </h3>
        </li>
      </ul>

      <span v-else>
        No Room found.
      </span>
      </horizontal-scroll>
    </vx-card>
    <!--teacher list-->
    <vx-card class="mb-base">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Teachers</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2">
          <vs-button
            v-if="selectedTeachers.length !== totalTeacherIds.length"
            class="ml-auto"
            @click="selectAllTeachers"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllTeachers">
            Unselect All
          </vs-button>

          <vs-button
            color="primary"
            type="filled"
            class="ml-2"
            @click="addTeacherHandler"
            >Add teacher</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- Avatar -->
      <horizontal-scroll>
      <ul class="room-list mb-4" v-if="teacherList.length">
        <li
          v-for="(teacher, index) in teacherList"
          v-bind:key="index"
          :class="selectedTeachers.includes(teacher._id) ? 'active' : ''"
        >
          <vs-avatar
            size="large"
            :src="teacher.profileImage"
            @click="selectTeacher(teacher, index)"
          />
          <h3
            class="h5"
            style="color: #289AD5; font-size: 14px; line-height: 18px;"
          >
            {{ firstNameOnly(teacher.fullName) }}
          </h3>
        </li>
      </ul>
      <span v-else>
        No Teachers in this room.
      </span>
      </horizontal-scroll>
    </vx-card>
    <!--children list-->
    <vx-card class="mb-base">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Children</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2 justify-end">
          <!--<vs-button
            v-if="selectedChildren.length !== totalChildrenIds.length"
            class="ml-auto"
            @click="selectAllChildren"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllChildren">
            Unselect All
          </vs-button>-->
          <vs-button
            color="primary"
            type="filled"
            class="ml-2"
            @click="addChildHandler"
            >Add child</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- Avatar -->
      <horizontal-scroll>
      <ul class="children-list room-list mb-4" v-if="childrenList.length">
        <span v-for="(child, index) in childrenList" v-bind:key="index">
          <!--<li
            :class="
              (selectedChildren.includes(child._id) ? 'active ' : '') +
                'relative inline-block mr-2'
            "
            @click="selectChild(child._id)"

          >-->

          <li
            :class="'active relative inline-block mr-2'"
            @click="goToChildProfile(child._id)"
          >
            <vs-avatar size="large" :src="child.photo" />
            <span class="badge">
              {{
                "undefined" != typeof child.activities &&
                child.activities.length
                  ? child.activities[0].numberOfActivities
                  : 0
              }}</span
            >
            <p>{{firstNameOnly(child.fullName)}}</p>
          </li>
        </span>
      </ul>
      <span v-else>
        No children in this room.
      </span>
      </horizontal-scroll>
    </vx-card>
    <!--    children activities-->
    <vx-card class="mb-base">
      <div class="flex flex-wrap">
        <h2>Children's Activities</h2>
        <div class="matrix-btn-wrap ml-auto mb-8"></div>
      </div>

      <!-- Five columns -->
      <vs-row class="bg-grid-color mb-4">
        <vs-col
          vs-type="flex"
          vs-lg-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <div class="flex datepicker-wrap">
            <span class="btn btn-outline-primary" @click="previousWeek"
              ><i class="feather icon-chevron-left" ></i
            ></span>
            <span class="reportrange-text">
              <date-picker
                v-model="week"
                type="week"
                placeholder="Select week"
                prefix-class="xmx"
                ref="picker"
                @change="pickerChange"
                :clearable="false"
                :lang="lang"
                :show-week-number="false"
              >
                <template v-slot:input="{ emit }">{{ dateRange }}</template>
              </date-picker>
            </span>
            <span class="btn btn-outline-primary" @click="nextWeek"
              ><i class="feather icon-chevron-right" ></i
            ></span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
        <ul class="status-list grid grid-cols-2 xl:grid-cols-4 w-full">
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #76c68f;"></span>
              <span class="font-regular">New</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #28388d;"></span>
              <span class="font-regular">Spontaneous</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #d1d1d1;"></span>
              <span class="font-regular">Complete</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #F3D16C;"></span>
              <span class="font-regular">Follow up</span>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <div class="overflow-auto">
        <table
          class="table children-activity-table"
          width="100%"
          v-if="displayView === 'Matrix'"
        >
          <template v-for="(stream, key) in streams">
            <tr v-if="key === 0" :key="key">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0 && key === 0" :key="ckey + 0.1"></td>
                <td v-if="key === 0" class="text-center" :key="ckey + 0.2">
                  <vx-tooltip v-bind:text="skill.description" position="bottom" >
                  <span class="img-block"
                    ><img
                      :src="skill.icon ? skill.icon : $defaultImage"
                      alt=""
                      class="product-img text-center"
                  /></span>
                  <p>{{ skill.name }}</p>
                </vx-tooltip>
                </td>
                <td
                  v-if="ckey > 0 && key > 0"
                  class="text-center"
                  :key="ckey + 0.4"
                ></td>
              </template>
            </tr>
            <tr :key="key + 0.5">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0" class="text-center" :key="ckey + 0.3">
                  <vx-tooltip v-bind:text="stream.description" position="right" >
                  <span class="img-block">
                    <img
                      :src="stream.icon ? stream.icon : $defaultImage"
                      alt=""
                      class="product-img"
                  /></span>
                  <p>{{ stream.name }}</p>
                </vx-tooltip>
                </td>
                <td class="text-center" :key="ckey + 0.4">
                  <ul class="status-list">
                    <li
                      v-for="(index, key) in activityMatrix(
                        skill._id,
                        stream._id
                      )"
                      :key="key"
                      style="display: inline-block"
                    >
                      <!-- <span
                        v-if="index.parentActivityId"
                        :class="
                          index.isCompleted
                            ? 'rounded-full complete'
                            : 'rounded-full follow-up'
                        "
                        @click="showActivityDetail(index)"
                        v-scroll-to="{ el: '#activity-detail', offset: -100 }"
                      ></span> -->
                      <span
                        v-if="index.isSpontaneous"
                        @click="showActivityDetail(index)"
                        :class="
                          index.isSpontaneous
                            ? 'rounded-full spontaneous'
                            : 'rounded-full follow-up'
                        "
                        v-scroll-to="{ el: '#activity-detail', offset: -100 }"
                      ></span>
                      <span
                        v-else
                        :class="'rounded-full ' + index.status[0].class"
                        @click="showActivityDetail(index)"
                        v-scroll-to="{ el: '#activity-detail', offset: -100 }"
                      ></span>
                      <vx-card
                        :class="
                          key === streams.length - 1 ||
                          key === streams.length - 2 ||
                          key === streams.length - 3 ||
                          ckey === coreSkills.length - 1
                            ? key < 3
                              ? 'vx-card-down'
                              : 'vx-card-up'
                            : ''
                        "
                      >
                        <span class="arrow"></span>
                        <h3>{{ index.name }}</h3>
                        <p>
                          {{
                            index.description.length > 160
                              ? index.description.substr(0, 160) + "..."
                              : index.description
                          }}
                        </p>
                        <ul>
                          <li>
                            <img
                              :src="
                                index.coreSkill.length
                                  ? index.coreSkill[0].icon
                                  : $defaultImage
                              "
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.waysOfLearning.length &&
                                index.waysOfLearning[0].icon
                                  ? index.waysOfLearning[0].icon
                                  : $defaultImage
                              "
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.streams.length && index.streams[0].icon
                                  ? index.streams[0].icon
                                  : $defaultImage
                              "
                              alt=""
                            />
                          </li>
                        </ul>
                      </vx-card>
                    </li>
                  </ul>
                </td>
              </template>
            </tr>
          </template>
        </table>
      </div>
    </vx-card>

    <vx-card class="mb-base">
      <h2>Learning Stories Overview</h2>
      <vs-row class="title-row">
        <vs-col vs-w="6">
          <h3 class="h4 font-semibold">
            Active Learning Stories: {{ activeLearningStories.length }}
          </h3>
        </vs-col>
        <vs-col vs-w="6">
          <h3 class="h4 font-semibold">
            Active Follow Ups: {{ totalFollowUp }}
          </h3>
        </vs-col>
      </vs-row>
      <vs-row
        v-for="(activity, key) in activeLearningStories"
        class="content-row"
        :key="key"
      >
        <vs-col vs-w="6">
          <div class="flex">
            <h3 class="font-semibold" style="min-width: 130px; width: 130px;">
              {{ daysAgoFromToday(activity.createdAt) }} Days
            </h3>
            <div>
              <h3 class="h4 font-semibold">{{ activity.name }}</h3>
              <p>{{ activity.description }}</p>
            </div>
          </div>
        </vs-col>
        <vs-col vs-w="6">
          <div
            v-for="(childFollowUp, key) in activity.followUp"
            :key="key"
            class="flex align-items-center mb-5"
          >
            <img :src="childFollowUp.child.photo" alt="" width="50px" />
            <p class="h5 font-medium">{{ childFollowUp.child.fullName }}</p>
            <span
              :class="
                childFollowUp.isCompleted
                  ? 'rounded-full complete'
                  : 'rounded-full follow-up'
              "
              style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 1px;"
            ></span>

            {{ childFollowUp.isCompleted ? "Complete" : "Active/In Progress" }}
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <div id="activity-detail">
      <activity-detail-page
        v-if="showActivityDetailPage === true"
        :selected-activity="selectedActivity"
      >
      </activity-detail-page>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueScrollTo from "vue-scrollto";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import VueLodash from "vue-lodash";
import moment from "moment";
import lodash from "lodash";
import ActivityDetailPage from "../../teacher/activity/ActivityDetailPage";
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

require("../../../../assets/scss/datepicker.scss");
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.use(VueScrollTo);

export default {
  components: { DatePicker, ActivityDetailPage,HorizontalScroll },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      centerId: "",
      roomId: "",
      title: "",
      roomList: "",
      teacherList: "",
      childrenList: "",
      activeRoomKey: 0,
      activeTeacherKey: 0,
      selectedRoom: "",
      selectedRoomName: "",
      selectedTeacher: "",
      learningStories: [],
      activeLearningStories:[],
      totalFollowUp: 0,
      week: new Date(),
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      pickerDateRange: "",
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek(),
      coreSkills: "",
      streams: "",
      waysOfLearnings: "",
      activitiesList: "",
      displayView: "Matrix",
      selectedActivity: "",
      showActivityDetailPage: false,
      selectedRooms: [],
      selectedTeachers: [],
      selectedChildren: [],
      totalRoomIds: [],
      totalTeacherIds: [],
      totalChildrenIds: []
    };
  },
  methods: {
    ...mapActions("center", [
      "fetchCentreRoomList",
      "getAdminActivity",
      "learningStoryDataForDashboardAdmin",
      "activeLearningStoryDataForDashboardAdmin"
    ]),
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    ...mapActions("stream", ["fetchStreams"]),
    ...mapActions("waysOfLearning", ["fetchWaysOfLearning"]),
    ...mapActions("room", ["getTeachersByRoomId", "getChildrenByRoomId"]),
    ...mapActions("centerAdmin", [
      "fetchTeacherByRoomIds",
      "fetchChildrenByMultipleRooms"
    ]),
    goToChildProfile(childId){
      this.$router.push({name: 'director-child-profile', params: {childId: childId}});
    },
    firstNameOnly(fullName){
      let name = fullName.split(' ');
      return name[0];
    },
    selectAllRooms() {
      this.selectedRooms = JSON.parse(JSON.stringify(this.totalRoomIds));
      this.getAllTeachersOfMultipleRooms(this.selectedRooms);
      this.getAllChildrenOfMultipleRoom(this.selectedRooms);
      this.getLearningStories(this.startDate, this.endDate);
      this.getActiveLearningStories()
    },
    unSelectAllRooms() {
      this.activitiesList = [];
      this.selectedRooms = [];
      this.teacherList = [];
      this.childrenList = [];
      this.selectedTeachers = [];
      this.selectedChildren = [];
    },
    selectAllTeachers() {
      this.selectedTeachers = JSON.parse(JSON.stringify(this.totalTeacherIds));
      this.getLearningStories(this.startDate, this.endDate);
      this.getActiveLearningStories()
    },
    unSelectAllTeachers() {
      this.selectedTeachers = [];
    },
    selectAllChildren() {
      this.selectedChildren = JSON.parse(JSON.stringify(this.totalChildrenIds));
      this.getLearningStories(this.startDate, this.endDate);
      this.getActiveLearningStories()
    },
    unSelectAllChildren() {
      this.selectedChildren = [];
    },
    showActivityDetail(activity) {
      this.selectedActivity = activity;
      this.showActivityDetailPage = true;
    },
    getRoomList(id) {
      this.$vs.loading();
      this.fetchCentreRoomList(id).then(res => {
        this.roomList = res.data.data;
        this.$vs.loading.close();
        if (this.roomList.length > 0) {
          this.totalRoomIds = res.data.data.map(item => {
            return item._id;
          });
          this.roomId = this.roomList[0]._id;
          this.activeTeacherKey = 0;
          this.selectedRooms.push(this.roomList[0]._id);

          this.getAllTeachersOfRoom(this.roomId, this.activeTeacherKey);
          this.getAllChildrenOfRoom(this.roomId);

          this.getLearningStories(this.startDate, this.endDate);
          this.getActiveLearningStories()
        }
        this.selectedRoomName = this.roomList[this.activeRoomKey].name;
      });
    },
    async getAllTeachersOfRoom(roomId, key) {
      this.selectedTeacher = "";
      await this.getTeachersByRoomId(roomId)
        .then(res => {
          this.activeTeacherKey = key;
          this.teacherList = res.data.data;
          this.totalTeacherIds = res.data.data.map(item => {
            return item._id;
          });

          this.selectedTeacher = this.teacherList[
            this.activeTeacherKey
          ].fullName;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getAllChildrenOfRoom(roomId) {
      this.getChildrenByRoomId(roomId)
        .then(res => {
          this.childrenList = res.data.data;
          this.totalChildrenIds = res.data.data.map(item => {
            return item._id;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async selectRoom(val, key) {
      this.selectedRoom = val;

      this.selectedRoomName = this.selectedRoom.name;
      if (this.selectedRooms.includes(val._id)) {
        if (this.selectedRooms.length < 2) {
          alert(
            "You cannot uncheck all rooms. You need to select at least one room."
          );
        } else {
          lodash.pull(this.selectedRooms, val._id);
        }
      } else {
        this.selectedRooms.push(val._id);
      }
      this.selectedTeachers = [];
      this.childrenList = [];
      this.getAllTeachersOfMultipleRooms(this.selectedRooms);
      this.getAllChildrenOfMultipleRoom(this.selectedRooms);
      this.getLearningStories(this.startDate, this.endDate);
      this.getActiveLearningStories()
      this.$vs.loading.close();
    },
    selectTeacher(val, key) {
      if (this.selectedTeachers.includes(val._id)) {
        if (this.selectedTeachers.length < 2) {
          alert(
            "You cannot uncheck all teachers. You need to select at least one room."
          );
        } else {
          lodash.pull(this.selectedTeachers, val._id);
        }
      } else {
        this.selectedTeachers.push(val._id);
      }
      this.getLearningStories(this.startDate, this.endDate);
      this.getActiveLearningStories()
      this.activeTeacherKey = key;
      this.selectedTeacher = val.fullName;
    },
    async getAllChildrenOfMultipleRoom(roomIds) {
      const children = await this.fetchChildrenByMultipleRooms(
        JSON.stringify(roomIds)
      );
      this.childrenList = children.data.data;
      this.totalChildrenIds = children.data.data.map(item => {
        return item._id;
      });
    },
    selectChild(childId) {
      if (this.selectedChildren.includes(childId)) {
        if (this.selectedChildren.length < 2) {
          alert(
            "You cannot uncheck all children. You need to select at least one child."
          );
        } else {
          lodash.pull(this.selectedChildren, childId);
        }
      } else {
        this.selectedChildren.push(childId);
      }
      this.getLearningStories(this.startDate, this.endDate);
      this.getActiveLearningStories()
    },
    async getAllTeachersOfMultipleRooms(roomIds) {
      const teachers = await this.fetchTeacherByRoomIds(
        JSON.stringify(roomIds)
      );
      this.teacherList = teachers.data.data;
      console.log(teachers.data.data);
      this.totalTeacherIds = teachers.data.data.map(item => {
        return item._id;
      });
    },
    async getLearningStories(
      startDate = this.startOfWeek(),
      endDate = this.endOfWeek()
    ) {
      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&centerId=" +
        this.centerId +
        "&roomIds=" +
        (this.selectedRooms.length > 0
          ? JSON.stringify(this.selectedRooms)
          : "") +
        "&teacherIds=" +
        (this.selectedTeachers.length > 0
          ? JSON.stringify(this.selectedTeachers)
          : "") +
        "&childrenIds=" +
        (this.selectedChildren.length > 0
          ? JSON.stringify(this.selectedChildren)
          : "");
      //this.$vs.loading();
      await this.learningStoryDataForDashboardAdmin(param)
        .then(res => {
          //this.$vs.loading.close();
          this.activitiesList = res.data.data;
          // this.learningStories = lodash.filter(this.activitiesList, function(
          //   activity
          // ) {
          //   return !activity.parentActivityId;
          // });

          // let $this = this;
          // lodash.forEach(this.learningStories, function(value, key) {
          //   $this.totalFollowUp = $this.totalFollowUp + value.followUp.length;
          // });
        })
        .catch(err => console.log(err));
      //this.$vs.loading.close();
    },
    async getActiveLearningStories() {
      let param =
        "?centerId=" +
        this.centerId +
        "&roomIds=" +
        (this.selectedRooms.length > 0
          ? JSON.stringify(this.selectedRooms)
          : "") +
        "&teacherIds=" +
        (this.selectedTeachers.length > 0
          ? JSON.stringify(this.selectedTeachers)
          : "") +
        "&childrenIds=" +
        (this.selectedChildren.length > 0
          ? JSON.stringify(this.selectedChildren)
          : "");
      this.$vs.loading();
      await this.activeLearningStoryDataForDashboardAdmin(param)
        .then(res => {
          this.$vs.loading.close();
          let result = res.data.data;

           let filteredLearningStories = [];
          lodash.forEach(result, function(value, key) {
            var completedFollowups = lodash.filter(value.followUp, function(o) { if (o.status[0].slug == 'complete') return o }).length;
            if(completedFollowups != value.followUp.length || value.followUp.length == 0){
                  filteredLearningStories.push(value);
            }
          });

          this.activeLearningStories = filteredLearningStories;

          let $this = this;
          let followUps = 0;
          lodash.forEach(this.activeLearningStories, function(value, key) {
            followUps = followUps + value.followUp.length;
          });
          $this.totalFollowUp = followUps;
        })
        .catch(err => console.log(err));
      this.$vs.loading.close();
    },
    // async getActivities(
    //   startDate = this.startOfWeek(),
    //   endDate = this.endOfWeek()
    // ) {
    //   let param =
    //     "?startDate=" +
    //     startDate +
    //     "&endDate=" +
    //     endDate +
    //     "&roomId=" +
    //     this.teacher.roomId;
    //   this.$vs.loading();
    //   await this.getAdminActivity(param).then(activities => {
    //     this.$vs.loading.close();
    //     this.activitiesList = activities.data.data;
    //   });
    //   this.$vs.loading.close();
    // },
    pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);

      this.dateRange = sDate + " - " + eDate;
      this.pickerDateRange = sDate + " - " + eDate;
      this.getLearningStories(sDate, eDate);
    },
    previousWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }
      this.getLearningStories(this.startDate, this.endDate);
    },
    nextWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }

      this.getLearningStories(this.startDate, this.endDate);
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");

      //return new Date(date.setDate(diff)).toISOString().slice(0, 10);
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");

      //return new Date(date.setDate(lastDate)).toISOString().slice(0, 10);
    },
    moment(date) {
      return moment(date);
    },
    activityMatrix(coreSkillId, streamId) {
      return this.lodash.filter(this.activitiesList, {
        coreSkills: coreSkillId,
        stream: streamId
      });
    },
    daysAgoFromToday(date) {
      let dt1 = new Date(date);
      let dt2 = new Date();
      return Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
    },
    addRoomHandler() {
      this.$router.push({
        name: "director-add-room",
        params: { centerId: this.centerId }
      });
    },
    addTeacherHandler() {
      this.$router.push({
        name: "director-teacher-add-learning-center",
        params: { id: this.centerId }
      });
    },
    addChildHandler() {
      this.$router.push({
        name: "director-children-add",
        params: { id: this.centerId }
      });
    }
  },
  async mounted() {
    // let self = this;
    // self.getLearningStories(self.startOfWeek(), self.endOfWeek());
    // self.getActiveLearningStories();

  },
  async created() {
    this.centerId = this.$route.params.centerId;

    this.title = this.$route.query.title;
    this.getRoomList(this.centerId);

    this.fetchCoreSkills()
      .then(res => {
        this.coreSkills = res.data.data;
      })
      .catch(err => console.log(err));

    this.fetchWaysOfLearning()
      .then(res => {
        this.waysOfLearnings = res.data.data;
      })
      .catch(err => console.log(err));

    this.fetchStreams()
      .then(res => {
        this.streams = res.data.data;
      })
      .catch(err => console.log(err));
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

.con-vs-avatar.large {
  width: 71px;
  height: 71px;
  @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }
}

.room-list {
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px) {
      max-width: 82px;
      margin-right: 10px;
    }

    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px) {
          border: 2px solid #289ad5;
        }
      }
    }
  }
}

.teacher-list {
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px) {
      max-width: 82px;
      margin-right: 10px;
    }

    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px) {
          border: 2px solid #289ad5;
        }
      }
    }
  }
}

.children-list .con-vs-avatar.large {
  width: 71px;
  height: 71px;
  margin-right: 19px;
  @media (min-width: 768px) {
    margin-right: 35px;
  }
}

.badge {
  background: #2898d5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -3px;
  right: 10px;
  @media (min-width: 768px) {
    right: 25px;
  }
}

.teacher-list h3 {
  color: #289ad5;
  font-size: 14px;
  line-height: 18px;
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

.new {
  background: #76c68f;
}

.complete {
  background: #d1d1d1;
}

.follow-up {
  background: #f3d16c;
}

.spontaneous {
  background: #28388d;
}

.title-row {
  border-top: 1px solid #d1d1d1;

  .vs-col {
    border-right: 1px solid #d1d1d1;
    padding: 20px;

    &:last-child {
      border-right: none;
    }
  }
}

.content-row {
  .vs-col {
    border-top: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    padding: 20px;

    &:last-child {
      border-right: none;
    }

    .h4 {
      color: #2c2c2c;
      margin-bottom: 10px;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 30px;
      object-fit: cover;
    }

    .h5 {
      font-weight: 500;
      color: #2c2c2c;
      width: 250px;
    }
  }
}
</style>
